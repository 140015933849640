import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQueryParam, StringParam } from 'use-query-params'
import { FaClock } from 'react-icons/fa'

import VideoPlayer from '../components/VideoPlayer'

const Video = () => {
  const [startTime, setStartTime] = useState(0)
  const [autoplay, setAutoplay] = useState(false)
  const [showTranscipt, setShowTranscipt] = useState(true)
  const [fetchedData, setFetchedData] = useState(false) // actual fetched videos
  const videoPath = useQueryParam("v", StringParam)[0]

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(`${process.env.GATSBY_API_URL}/v1/video/byPath?path=/video/${videoPath}`, {
        method: 'get', mode: 'cors'
      })
      const data = await result.json()
      setFetchedData(data)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateStartTime = ((time) => {
    setAutoplay(true)
    setStartTime(time)
  })

  const timeFromDuration = (duration) => {
    let h, m//,s
    h = Math.floor(duration / 1000 / 60 / 60)
    m = Math.floor((duration / 1000 / 60 / 60 - h) * 60)
    // s = Math.floor(((duration/1000/60/60 - h)*60 - m)*60)

    m = m < 59 ? m = m + 1 : m  // hack, lets get to the next minute, stop displaying seconds

    // s < 10 ? s = `0${s}`: s = `${s}`
    m < 10 ? m = `0${m}` : m = `${m}`
    h < 10 ? h = `0${h}` : h = `${h}`

    return `
      ${h > 0 ? `${h} hour${h > 1 ? 's' : ''} ` : ''}
      ${m} minute${m > 1 ? 's' : ''}
    `
  }

  const fetched = fetchedData !== false
  let videoMinutes;
  if (fetched) {
    let lastStart = fetchedData.transcript.text[fetchedData.transcript.text.length - 1].start
    let lastDuration = fetchedData.transcript.text[fetchedData.transcript.text.length - 1].duration

    let duration = lastStart + lastDuration

    videoMinutes = timeFromDuration(duration)
  }

  return (
    <>
      {fetched
        ? <div className="page--video wrapper">
          <Helmet title={`Video - ${fetchedData.video.title} | Couchbase Developer Portal`} />
          <div className="flex flex-row">
            <div className={`w-full`}>
              <h1 id="video-heading">{fetchedData.video.title}</h1>
              <p>{fetchedData.video.description}</p>
              <VideoPlayer
                videoCode={fetchedData.video.youtubeId}
                autoplay={autoplay} start={startTime} controls={true}
              />
              <p>
                <FaClock size={16} style={{ display: 'inline', marginTop: '-4px' }} className="mr-2" />
                <strong>Video Duration: </strong>{videoMinutes}
              </p>
              <div className="mt-2 mb-1">
                <p>
                  <button className="cb-red-button cursor-pointer" onClick={() => setShowTranscipt(!showTranscipt)}>
                    {showTranscipt ? 'Hide' : 'Show'} Transcripts
                  </button>
                </p>
              </div>
              <div className={`w-full flex flex-col mt-2 ${showTranscipt ? '' : 'hidden'}`}>
                <hr className="mb-2 border-gray-700" />
                <p>{
                  fetchedData.transcript.text.map((t, i) =>
                    <span className="transcript-txt" key={i}>
                      <a className="cursor-pointer transcript-text-block" href="#video-heading" onClick={() => updateStartTime(Math.trunc(t.start / 1000))}>
                        {`${t.text} `}
                      </a>
                    </span>
                  )
                }</p>
              </div>

            </div>
          </div>
        </div>
        : null
      }
    </>
  )
}

export default Video
